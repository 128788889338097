
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import * as Yup from 'yup';
import { Box, Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import config from '../config';
import Link from '../components/Link';
import Page from '../components/Page';
import { StoreContext } from '../store';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useStyles } from '../styles/components/Auth.styles';
import { Error404Illustration, LogoAuthIllustration } from '../components/Illustrations';
import Hidden from '../components/HiddenSSRCompatible';
export default function Custom404() {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const store = useContext(StoreContext);
    return (<Page maxWidth="lg">
      <Box className={classes.box} mx={2}>
        <Paper style={{ borderRadius: "10px" }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Hidden mdUp>
                <Box style={{ height: "100%" }} p={2}>
                  <Box style={{ backgroundColor: "#D9D9D9", borderRadius: "10px", height: "100%" }} p={1}>
                    <Grid style={{ height: "100%" }} container>
                      <Grid style={{ height: "100%" }} item xs={12}>
                        <Error404Illustration width={"100%"}/>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Hidden>
              <Hidden smDown>
                <Box style={{ height: "100%" }} p={2}>
                  <Box style={{ backgroundColor: "#D9D9D9", borderRadius: "10px", height: "100%" }} p={10}>
                    <Grid style={{ height: "100%" }} container>
                      <Grid style={{ height: "100%" }} item xs={12}>
                        <Error404Illustration width={"100%"}/>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Hidden>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mt={2} mb={2}>
                <Hidden smUp>
                  <Box py={2} px={4} style={{ textAlign: "center" }}>
                    <Typography variant='overline' style={{ color: "#FFD140" }}>404 error</Typography>
                    <Typography style={{ fontWeight: "600" }} variant='h3'>{`Oops!`}</Typography>
                    <Typography style={{ fontWeight: "600", marginTop: "5px" }} variant='h6'>{`Halamannya Tidak Ketemu :(`}</Typography>
                    <Typography variant='body2' style={{ marginTop: "5px", marginBottom: "20px" }}>Maaf, halaman yang Anda cari tidak dapat ditemukan.</Typography>
                    
                    <Link href="/signin" data-cy="signup">
                      <Button variant="contained" style={{ color: "white", backgroundColor: "#1f1f1f" }} color="#1f1f1f">
                        kembali ke Superkos  
                      </Button>
                    </Link>
                  </Box>
                </Hidden>
                <Hidden xsDown>
                  <Box py={10} px={4} style={{ textAlign: "center" }}>
                    <Typography variant='overline' style={{ color: "#FFD140" }}>404 error</Typography>
                    <Typography style={{ fontWeight: "600" }} variant='h3'>{`Oops!`}</Typography>
                    <Typography style={{ fontWeight: "600", marginTop: "5px" }} variant='h6'>{`Halamannya Tidak Ketemu :(`}</Typography>
                    <Typography variant='body2' style={{ marginTop: "5px", marginBottom: "20px" }}>Maaf, halaman yang Anda cari tidak dapat ditemukan.</Typography>
                    
                    <Link href="/signin" data-cy="signup">
                      <Button variant="contained" style={{ color: "white", backgroundColor: "#1f1f1f" }} color="#1f1f1f">
                        kembali ke Superkos  
                      </Button>
                    </Link>
                  </Box>
                </Hidden>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

    </Page>);
}

    async function __Next_Translate__getStaticProps__19503f78b03__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19503f78b03__ as getStaticProps }
  